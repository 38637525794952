import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Cookies from 'react-cookies';
import LoadingPlaceholder from '../../components/_generic/loading-placeholder/LoadingPlaceholder';
import COOKIES from '../../config/cookies/cookies';
import { SITE_LIVINGSOCIAL_IE, SITE_WOWCHERIE } from '../../config/setup/setup';
import { updatePersistentProperty } from '../../helpers/analytics';
import { getServerSideEssentials } from '../../redux/actions/ssr';
import { wrapper } from '../../redux/store/store';

const Redirects = () => {
  const router = useRouter();

  // Redirect to Local URL
  const isWowIE = process.env.NEXT_PUBLIC_SITE === SITE_WOWCHERIE;
  const locationCookie = Cookies.load(COOKIES.location);
  const landingPageCookie = Cookies.load(COOKIES.landingPage);
  const defaultLocation =
    process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL_IE
      ? 'dublin-south'
      : isWowIE
      ? 'dublin'
      : 'london';

  const homePageRedirectSlug =
    (locationCookie?.shortName && !isWowIE) ||
    (isWowIE && locationCookie?.shortName === 'dublin')
      ? locationCookie?.shortName
      : defaultLocation;
  const localUrl = `/deals/${homePageRedirectSlug}`;
  const nationalUrl = landingPageCookie === 'a' ? `/deals/shop` : localUrl;
  updatePersistentProperty('homepageRedirectedTo', nationalUrl);
  let url = nationalUrl;

  const currentdate = new Date();

  // we want to have a test National deal switch on 13/12/2022 12am
  const localDayOn = dayjs('2024-03-07 00:00');
  const localDayOff = dayjs('2024-03-10 23:59');
  const localDayOnDiff = localDayOn.diff(currentdate, 'second');
  const localDayOffDiff = localDayOff.diff(currentdate, 'second');

  if (localDayOnDiff <= 0 && localDayOffDiff > 0) {
    url = localUrl;
  }
  // Switching homepage to National to Local for both LS and LS IE
  // if (process.env.NEXT_PUBLIC_SITE !== 'wowcher') {
  //   url = nationalUrl;
  // }

  // we want to have the Christmas National deal switch on 24/12/2021 after 23:50
  /*
  const currentdate = new Date();
  const currentYear = currentdate.getFullYear();

  const christmasDay = dayjs(`${currentYear}-12-24 23:50`);
  const christmasDayDiff = christmasDay.diff(currentdate, 'second');

  if (christmasDayDiff <= 0) {
    url = localUrl;
  }*/

  useEffect(() => {
    if (typeof window !== 'undefined') {
      router.replace(url);
    }
  }, []);

  return <LoadingPlaceholder />;
};

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ req, store }) => {
    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      console.log(`getServerSideEssentials error: ${error}`);
    });
  },
);

export default Redirects;
